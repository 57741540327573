<template>
  <v-dialog v-model="dialog" persistent width="1200">
    <v-card
      style="display: flex; flex-direction: column;"
      :style="$vuetify.breakpoint.xs ? '' : 'max-height: 80vh'"
    >
      <v-card-title class="d-flex" style="justify-content: space-between;">
        Preview
        <v-btn icon @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="flex-grow: 1; overflow-y: auto;">
        <div class="feed-body">
          <div class="feed-contents quillWrapper" v-if="blog">
            <div class="d-flex align-center">
              <h2 class="mt-5">{{ this.blog.title }}</h2>
              <v-spacer />
            </div>
            <v-img :src="this.blog.cover" alt="" width="100%" class="mt-8" />
            <div class="post-content ql-editor" v-html="this.blog.html"></div>
          </div>
          <v-progress-circular
            indeterminate
            color="amber"
            v-else
            class="mt-6"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "BlogPreview",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    blog: Object,
    onClose: Function,
  },
  methods: {
    ...mapActions("blog", {
      getBlogDetails: "getBlogDetails",
    }),
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
}
.feed-contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
h4 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 24px;
}
</style>
